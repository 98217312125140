import {
  UserOutlined,
  CarOutlined,
  FileDoneOutlined,
  PoweroffOutlined,
  SettingOutlined,
  MenuOutlined,
  DashboardOutlined,
  EuroOutlined,
  ThunderboltOutlined,
  FileOutlined,
  CalendarOutlined,
  QuestionCircleOutlined,
  PieChartOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { INSPECTO_HELP_EMAIL_ADDRESS, useCompany } from "@inspecto/common";
import {
  Button,
  Divider,
  Drawer,
  Grid,
  Layout,
  Menu,
  Modal,
  Popover,
  Result,
  Space,
  Typography,
} from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Route,
  Switch,
  Redirect,
  NavLink,
  useLocation,
  Link,
} from "react-router-dom";
import styled from "styled-components";

import { useAuthentication } from "src/authentication";
import { Permission } from "src/authentication/models";
import { CompanyLogo } from "src/company";
import { UserAvatar } from "src/components";
import { DeploymentInfoMessage, useDeploymentInfo } from "src/deploymentInfo";
import inspectoLogoBlue from "src/static/inspectoLogoBlue.svg";
import { myTheme } from "src/theme";
import { urls } from "src/urls";
import { fullName } from "src/utils";

import { PinDisplay, ScheduleFreeTrainingModal } from "../components";
import {
  LayoutHeaderFixed,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LogoImg24,
  LogoWrapper,
  sidebarWidth,
  StyledSider,
} from "./BackOffice.styles";
import { DamagesRouter } from "./damages";
import { DashboardRouter } from "./dashboard";
import { EmployeeChargesRouter } from "./employeeCharges";
import { EmployeesRouter } from "./employees";
import { FleetStateRouter } from "./fleetState";
import { ProtocolsRouter } from "./protocols";
import { ReportsRouter } from "./reports";
import { SettingsRouter } from "./settings";
import { TemplateBuilderRouter } from "./templateBuilder";
import { VehiclesRouter } from "./vehicles";

interface MenuItem {
  url: string;
  label: string;
  router: JSX.Element;
  icon?: JSX.Element;
  subRoutes?: { url: string; label: string }[];
  requiredPermission: Permission;
}

type MenuItems = MenuItem[];

interface MenuWithLogoutButtonProps {
  menuItems: MenuItems;
}

const CustomYellowButton = styled(Button).attrs({
  type: "text",
  ghost: true,
  size: "small",
})`
  background-color: #fffcde;
  border: 1px solid #e8c654;
  color: #a76f00;
  font-weight: 500;
  height: auto;
  text-align: left;
  text-wrap: wrap;

  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    background-color: #fffef6;
    border-color: #bd9a24;
    color: #6d4d0d;
  }
`;

function BackofficeMenu(props: MenuWithLogoutButtonProps): JSX.Element {
  const location = useLocation();
  const getSelectedKeys = (
    menuItems: (MenuItem | { url: string; label: string })[]
  ): string[] => {
    const routes = [];
    if (!menuItems) {
      return [];
    }
    for (const menuItem of menuItems) {
      if ("subRoutes" in menuItem && menuItem.subRoutes) {
        routes.push(...getSelectedKeys(menuItem.subRoutes));
      }
      if (location.pathname.includes(menuItem.url)) {
        routes.push(menuItem.url);
      }
    }
    return routes;
  };

  const getMenuItem = (
    menuItem: MenuItem | { url: string; label: string }
  ): ItemType => ({
    icon: "icon" in menuItem && menuItem.icon,
    key: menuItem.url,
    label: (
      <NavLink to={menuItem.url} exact={false}>
        {menuItem.label}
      </NavLink>
    ),
  });

  return (
    <>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={getSelectedKeys(props.menuItems)}
        defaultOpenKeys={getSelectedKeys(props.menuItems)}
        style={{
          borderRightColor: "#fff",
        }}
        items={props.menuItems.map((urlConfiguration) =>
          "subRoutes" in urlConfiguration
            ? {
                label: urlConfiguration.label,
                icon: urlConfiguration.icon,
                key: urlConfiguration.url,
                children:
                  urlConfiguration.subRoutes &&
                  urlConfiguration.subRoutes.map((menuItem) =>
                    getMenuItem(menuItem)
                  ),
              }
            : getMenuItem(urlConfiguration)
        )}
      />
    </>
  );
}

export function BackOffice(): JSX.Element {
  const company = useCompany();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.xl;

  const { isInDeployment } = useDeploymentInfo();

  const { user, logoutAndRedirectToLandingPage, hasPerm } = useAuthentication();
  const location = useLocation();
  const { t } = useTranslation("backoffice");
  const { t: tTranslation } = useTranslation("translation");
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const layoutHeaderFixedRef = useRef<HTMLDivElement>(null);
  const [
    isScheduleFreeTrainingModalVisible,
    setIsScheduleFreeTrainingModalVisible,
  ] = useState(false);
  const scheduleFreeTrainingModalOnSuccess = useCallback(() => {
    setIsScheduleFreeTrainingModalVisible(false);
    Modal.success({
      title: t("scheduleFreeTraining.confirmationMessage"),
    });
  }, [t]);
  const scheduleFreeTrainingModalCloseModal = useCallback(() => {
    setIsScheduleFreeTrainingModalVisible(false);
  }, []);
  const getPopupContainerForFixedHeader = () =>
    layoutHeaderFixedRef.current || document.body;

  useEffect(() => {
    if (isMobileMenuVisible) {
      setIsMobileMenuVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const _allMenuItems: MenuItem[] = useMemo(
    () => [
      {
        url: urls.backOffice.dashboard(),
        label: t("dashboard"),
        router: <DashboardRouter />,
        icon: <DashboardOutlined />,
        requiredPermission: "backoffice.dashboard",
      },
      {
        url: urls.backOffice.vehicles(),
        label: t("vehiclesView.pageTitle"),
        router: <VehiclesRouter />,
        icon: <CarOutlined />,
        requiredPermission: "backoffice.vehicles.read",
      },
      {
        url: urls.backOffice.protocols(),
        label: t("protocolsView.pageTitle"),
        router: <ProtocolsRouter />,
        icon: <FileDoneOutlined />,
        requiredPermission: "backoffice.protocols.read",
      },
      {
        url: urls.backOffice.reports.main(),
        label: t("reportsView.pageTitle"),
        router: <ReportsRouter />,
        icon: <PieChartOutlined />,
        requiredPermission: "backoffice.reports",
      },
      {
        url: urls.backOffice.fleetState.customVehicleFields(),
        label: t("fleetState.pageTitle"),
        router: <FleetStateRouter />,
        icon: <CalendarOutlined />,
        requiredPermission: "backoffice.fleet_state.read",
      },
      {
        url: urls.backOffice.employees(),
        label: t("employees"),
        router: <EmployeesRouter />,
        icon: <UserOutlined />,
        requiredPermission: "backoffice.employees.read",
      },
      {
        url: urls.backOffice.damages.list(),
        label: t("damages.pageTitle"),
        router: <DamagesRouter />,
        icon: <ThunderboltOutlined />,
        requiredPermission: "backoffice.damages.read",
      },
      {
        url: urls.backOffice.employeeCharges(),
        label: t("employeeCharges.pageTitle"),
        router: <EmployeeChargesRouter />,
        icon: <EuroOutlined />,
        requiredPermission: "backoffice.charged_employees.read",
      },
      {
        url: urls.backOffice.templateBuilder(),
        label: t("builder.templates"),
        router: <TemplateBuilderRouter />,
        icon: <FileOutlined />,
        requiredPermission: "backoffice.templates.read",
      },
      {
        label: t("settings.label"),
        icon: <SettingOutlined />,
        url: urls.backOffice.settings.main(),
        router: (
          <SettingsRouter
            enableCompanyDepartmentsRoute={
              company.allowManagingCompanyDepartments
            }
          />
        ),
        requiredPermission: "backoffice.settings.write", // Currently we only do write permissions for templates, as read is needed for filters
        subRoutes: [
          {
            url: urls.backOffice.settings.vehicleGroups(),
            label: t("settings.vehicleGroups.label"),
          },
          {
            url: urls.backOffice.settings.templateTags(),
            label: t("settings.templateTags.label"),
          },
          {
            url: urls.backOffice.settings.customVehicleFields(),
            label: t("settings.customVehicleFields.label"),
          },
          {
            url: urls.backOffice.settings.customVehicleFieldsGroups(),
            label: t("settings.customVehicleFieldsGroups.label"),
          },
          {
            url: urls.backOffice.settings.vehicleFieldsPresets(),
            label: t("settings.vehicleFieldsPresets.label"),
          },
          {
            url: urls.backOffice.settings.notifications.baseView(),
            label: t("settings.notifications.label"),
          },
          ...(company.allowManagingCompanyDepartments
            ? [
                {
                  url: urls.backOffice.settings.companyDepartments(),
                  label: t("settings.companyDepartments.listLabel"),
                },
              ]
            : []),
        ],
      },
    ],
    [company.allowManagingCompanyDepartments, t]
  );
  const menuItems: MenuItem[] = useMemo(
    () =>
      _allMenuItems.filter((item: MenuItem) =>
        hasPerm(item.requiredPermission)
      ),
    [_allMenuItems, hasPerm]
  );

  if (!user) {
    return <div />;
  }
  const firstMenuItem = menuItems.length ? menuItems[0] : null;
  let redirectUrl = firstMenuItem?.url;
  if (
    firstMenuItem &&
    "subRoutes" in firstMenuItem &&
    firstMenuItem.subRoutes &&
    firstMenuItem.subRoutes[0]
  ) {
    redirectUrl = firstMenuItem.subRoutes[0].url;
  }

  const scheduleFreeTrainingButton = (
    <CustomYellowButton
      style={{ marginRight: 10 }}
      onClick={() => setIsScheduleFreeTrainingModalVisible(true)}
    >
      <Space>
        <CustomerServiceOutlined style={{ fontSize: 16 }} />
        {t("scheduleFreeTraining.title")}
      </Space>
    </CustomYellowButton>
  );

  return (
    <Layout
      style={{
        marginLeft: isMobile ? 0 : sidebarWidth,
        position: "relative",
      }}
    >
      {isMobile ? (
        <Drawer
          placement="left"
          closable={true}
          onClose={() => setIsMobileMenuVisible(false)}
          open={isMobileMenuVisible}
          key="leftDrawer"
          width={sidebarWidth}
          title="Menu"
          bodyStyle={{ padding: "10px 0 0 0" }}
        >
          <div
            style={{
              padding: "0 5px 10px 15px",
            }}
          >
            {scheduleFreeTrainingButton}
          </div>
          <BackofficeMenu menuItems={menuItems} />
        </Drawer>
      ) : (
        <StyledSider>
          <LogoWrapper>
            <Link to={urls.landingPage()}>
              <LogoImg24 src={inspectoLogoBlue} alt="Inspecto" />
            </Link>
          </LogoWrapper>

          <BackofficeMenu menuItems={menuItems} />
        </StyledSider>
      )}
      {isInDeployment && (
        <DeploymentInfoMessage marginLeft={isMobile ? 0 : sidebarWidth} />
      )}
      <LayoutHeaderFixed
        isInDeployment={isInDeployment}
        hasLeftPadding={!isMobile}
        ref={layoutHeaderFixedRef}
      >
        <LayoutHeaderLeft>
          {isMobile ? (
            <Space size="large" align="center">
              <Button
                icon={<MenuOutlined />}
                size="small"
                onClick={() => setIsMobileMenuVisible(true)}
              />
              <Link to={urls.landingPage()}>
                <LogoImg24 src={inspectoLogoBlue} alt="Inspecto" />
              </Link>
            </Space>
          ) : (
            company.logo && (
              <CompanyLogo
                size={{
                  maxHeight: 38,
                  maxWidth: 120,
                }}
              />
            )
          )}
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          {!isMobile && scheduleFreeTrainingButton}
          {isScheduleFreeTrainingModalVisible && (
            <ScheduleFreeTrainingModal
              onSuccess={scheduleFreeTrainingModalOnSuccess}
              closeModal={scheduleFreeTrainingModalCloseModal}
            />
          )}
          <Popover
            getPopupContainer={getPopupContainerForFixedHeader}
            placement={isMobile ? "bottomRight" : "bottom"}
            title={t("topBarActions.needHelpQuestion")}
            content={
              <>
                <strong>{t("topBarActions.feelFreeToContactUs")}</strong>
                <div>
                  <Typography.Link
                    href={`mailto:${INSPECTO_HELP_EMAIL_ADDRESS}`}
                  >
                    {INSPECTO_HELP_EMAIL_ADDRESS}
                  </Typography.Link>
                </div>
              </>
            }
            trigger="click"
          >
            <Button
              size="small"
              icon={<QuestionCircleOutlined />}
              type={isMobile ? undefined : "text"}
              shape={isMobile ? "circle" : "round"}
            >
              {isMobile ? "" : t("topBarActions.helpButtonLabel")}
            </Button>
          </Popover>
          <Popover
            getPopupContainer={getPopupContainerForFixedHeader}
            placement="bottomRight"
            title={fullName(user)}
            content={
              <>
                <div>
                  {t("email")}:{" "}
                  <Typography.Link href={`mailto:${user.email}`}>
                    {user.email}
                  </Typography.Link>
                </div>
                <div>
                  {t("userRole")}: <strong>{user.userRoleName}</strong>
                </div>
                {user.loginPin && (
                  <>
                    <Divider style={{ margin: "12px 0 4px 0" }} />
                    <div>
                      <Space>
                        <span>{t("loginPin")}:</span>
                        <PinDisplay pin={user.loginPin} />
                      </Space>
                    </div>
                  </>
                )}
                <Divider style={{ margin: "6px 0 12px 0" }} />
                <Button
                  onClick={logoutAndRedirectToLandingPage}
                  block
                  ghost
                  type="primary"
                  size="small"
                  icon={<PoweroffOutlined />}
                >
                  {tTranslation("logout")}
                </Button>
              </>
            }
            trigger="click"
          >
            <UserAvatar
              user={user}
              style={{
                marginRight: 5,
                marginLeft: isMobile ? 5 : 0,
                cursor: "pointer",
              }}
            />
          </Popover>
        </LayoutHeaderRight>
      </LayoutHeaderFixed>
      <Switch>
        {menuItems.map((urlConfiguration) => (
          <Route
            path={urlConfiguration.url}
            exact={false}
            key={urlConfiguration.url}
          >
            {urlConfiguration.router}
          </Route>
        ))}
        <Route path="*">
          {redirectUrl ? (
            <Redirect to={redirectUrl} />
          ) : (
            <Result
              style={{
                marginTop: myTheme.commonVariables.backOfficeHeaderHeight,
              }}
              status="403"
              title="403"
              subTitle="Sorry, you don't have access to any module"
            />
          )}
        </Route>
      </Switch>
    </Layout>
  );
}
