import { createContext, useContext } from "react";

import { defaultCurrency } from "src/currencies";
import { allProtocolFillerLanguages, defaultLanguage } from "src/i18n";
import { Company, ProtocolFillerVehicleSelectionMethod } from "src/models";

export const companyContextInitialValue: Company = {
  id: "",
  name: "",
  logo: null,
  subdomain: "",
  language: defaultLanguage,
  protocolFillerLanguage: defaultLanguage,
  availableCurrencies: [defaultCurrency],
  isActive: true,
  redirectToCompanySubdomain: null,
  availableLanguages: allProtocolFillerLanguages,
  availableReports: [],
  displayVehiclePositionInProtocolFillerVehicleSelector: false,
  useVehicleFieldsInsteadOfLastProtocolAsVehicleStatus: false,
  allowManagingCompanyDepartments: false,
  useExternalCameraApplicationInMobileApp: false,
  protocolFillerVehicleSelectionMethod:
    ProtocolFillerVehicleSelectionMethod.PLATE_NUMBER_OR_QR_CODE,
  allowStoringHighQualityImages: false,
  requireTemplateTagFilterSelectionInProtocolFiller: false,
  automaticallyLogOutAfterCreatingProtocol: false,
  allowAddingPhotosOnDesktopInProtocolFiller: false,
  allowStoringDocumentsOnVehicles: false,
};

export const companyContext = createContext<Company>(
  companyContextInitialValue
);
export const { Provider: CompanyContextProvider } = companyContext;

export function useCompany() {
  return useContext(companyContext);
}
